import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

interface Event {
  label: string;
  filter: Filter[];
  customData?: any;
}

interface Filter {
  type: string;
  value: number | string;
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: WebSocket = new WebSocket(environment.websocketURL);
  private events: Event[] = [];
  private messageSubject: Subject<any> = new Subject<any>();
  private webSocketStateSubject: Subject<number> = new Subject<number>();
  private routeChangeSubscription: Subscription = new Subscription();

  constructor(private router: Router) { }

  public openWebsocket(): void {
    this.handleRouteChanges();

    if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
      return;
    }
    this.socket = new WebSocket(environment.websocketURL);
    this.socket.addEventListener('open', () => {
      this.webSocketStateSubject.next(WebSocket.OPEN);
    });

    this.socket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      this.messageSubject.next(message); // Notify subscribers with the new message
    });
    this.socket.addEventListener('error', () => {
      this.webSocketStateSubject.next(WebSocket.CLOSED);
    });
    this.socket.addEventListener('close', () => {
      this.webSocketStateSubject.next(WebSocket.CLOSED);
    });
  }

  public onMessage(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  private safeSend(data: string): void {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(data);
    } else {
      this.socket.addEventListener('open', () => this.socket.send(data), { once: true });
    }
  }

  public createEvent(label: string, filter?: Filter[] | any, customData?: any) {
    const event = { label, filter: filter || [], customData };
    this.events.push(event);
  }

  public subscribeToWebsocket() {
    const message = {
      action: 'subscribe',
      message: { events: this.events }
    };
    this.safeSend(JSON.stringify(message));
  }

  private unsubscribeFromWebsocket() {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ action: 'unsubscribe' })); // Send unsubscribe action
    }
  }

  public onWebSocketStateChange(): Observable<number> {
    return this.webSocketStateSubject.asObservable();
  }

  public clearEvents() {
    this.events = []; // Clear registered events
  }

  private handleRouteChanges() {
    this.routeChangeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.cleanupOnNavigation(); // Cleanup without closing WebSocket
      }
    });
  }

  public closeWebsocket() {
    if (this.socket) {
      this.socket.close();
    }
  }

  // Renamed to reflect the actual operation more accurately
  public cleanupOnNavigation() {
    this.clearEvents();
    this.unsubscribeFromWebsocket();
    if (this.routeChangeSubscription) {
      this.routeChangeSubscription.unsubscribe();
    }
  }
}
